<template lang="pug">
el-dialog.dialog-style-title(
    :visible="visible"
    width="340px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="dialog-style"
    :append-to-body="true"
    :show-close="showClose"
)
    div.dialog-title(slot="title") 设置昵称
    div.dialog-content
        div.input-container
            el-input.input(v-model="inputName" type="text" maxlength="24" placeholder="请输入你的昵称")
    div.dialog-footer(slot="footer")
        el-button(@click="handleClose" :disabled="!showClose") {{ $t('common.Cancel') }}
        el-button(type="primary" @click="handleSure") {{ $t('common.Sure') }}
</template>

<script>
import { mapState } from 'vuex';
import {
  UPDATE_USER_INFO,
} from 'constants/mutation-types';
import { updateUserName } from '@/api';
export default {
  name: 'userNameSetting',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      inputName: '',
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
    }),
  },
  watch: {
    'userInfo.userName': {
      immediate: true,
      handler(val) {
        this.inputName = val;
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
    async handleSure() {
      if (!this.inputName) return this.$message.error('昵称不能为空');
      await updateUserName(this.inputName);
      this.handleClose();

      this.$store.commit(UPDATE_USER_INFO, { ...this.userInfo, userName: this.inputName });
    },
  },
};
</script>

<style lang="stylus" scoped>
.dialog-title
  font-weight bold
  color $fontColor
  font-size 16px
.dialog-content
  padding 0 10px
  text-align left
  .title
    font-weight bold
    font-size 16px
    display inline-block
    margin-bottom 14px
  .input-container
    position relative
    margin-bottom 30px
    .sure-button
      height 40px
      line-height 40px
      position absolute
      right 12px
      cursor pointer
      font-size 14px
.dialog-footer
  width 100%
  height 100%
  text-align center
</style>
