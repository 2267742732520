<!--
 * @Description: 项目简介组件
 * @Date: 2022-01-25 16:05:22
 * @LastEditTime: 2022-02-21 15:23:21
-->
<template lang="pug">
  div.info-container(
    v-html="roomIntroduce"
  )
    p.info-message
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'video-record',
  computed: {
    ...mapState({
      roomIntroduce: 'roomIntroduce',
    }),
  },
};
</script>

<style lang="stylus" scoped>
  .info-container
    width 100%
    height 100%
    color $grayFontColor
    padding 20px 25px 25px
    font-size 16px
    text-align left
    word-break break-all
</style>

