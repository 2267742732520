import pako from 'pako';

// 解压
export function unzip(b64Data) {
  let strData = atob(b64Data);
  const charData = strData.split('').map(x => x.charCodeAt(0));
  const binData = new Uint8Array(charData);
  const data = pako.inflate(binData);
  strData = String.fromCharCode.apply(null, new Uint16Array(data));
  return decodeURIComponent(strData);
}

// 压缩
export function zip(str) {
  const binaryString = pako.gzip(encodeURIComponent(str), { to: 'string' });
  return btoa(binaryString);
}
