import axios from 'axios';
import { serialize, parse } from 'cookie-es';
import { ApiNames, RequestBaseUrl } from './base';
import { convertToCamel } from '@/utils/utils';

// 用于本地测试储存用户信息的 cookie key，可以换成您实际自己的 key
const AuiUserNameCookieKey = 'aui_usernick';
const AuiUserIdCookieKey = 'aui_userid';
const AuiUserToken = 'aui_token';

class Services {
  authToken = '';
  userId = '';
  userName = '';
  // 创建 axios 实例
  request = axios.create({
    baseURL: RequestBaseUrl,
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  constructor() {
    // 统一处理接口返回
    this.request.interceptors.response.use((res) => {
      if (res.status === 200 && res.data) {
        return res.data;
      }
      return this.handleError(res);
    }, error => this.handleError(error));
  }

  // 在这个函数中处理特殊，比如 token 失效时主动 refresh、登录失效时提示用户
  handleError(error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    // 特殊异常处理，若 401 是登录失效，可以在这里做弹窗提示等操作
    // if (error.response.status === 401) {}
    return Promise.reject(error);
  }

  getUserInfo() {
    return {
      userId: this.userId,
      userName: this.userName,
    };
  }

  // demo 测试检测是否登录逻辑，正式使用时需要您自行实现真正的判断逻辑
  checkLogin() {
    return new Promise((resolve, reject) => {
      const cookieObj = parse(document.cookie);
      if (cookieObj[AuiUserNameCookieKey] && cookieObj[AuiUserIdCookieKey] && cookieObj[AuiUserToken]) {
        // 若 cookie 中有用户相关信息，则判断是已登录
        this.authToken = cookieObj[AuiUserToken];
        this.userId = cookieObj[AuiUserIdCookieKey];
        this.userName = cookieObj[AuiUserNameCookieKey];

        this.setHeaderAuthorization();

        resolve({
          userName: cookieObj[AuiUserNameCookieKey],
          userId: cookieObj[AuiUserIdCookieKey],
          token: cookieObj[AuiUserToken],
        });
        return;
      }
      reject();
    });
  }

  // 此处登录逻辑为示例逻辑，实际开发请接入 SSO 单点登录、OAuth2 等方案，请勿使用明文密码
  async login(userId, username) {
    try {
      const res = await this.request.post(ApiNames.login, {
        username,
        password: username, // 示例逻辑，目前 Appserver 层判断 username 得相等 password，实际开发请勿使用明文密码的方式登录
      }, {
        headers: {},
      });
      const { code, token, expire } = res;
      if (code === 200) {
        this.authToken = token;
        this.userName = username;
        this.userId = userId;

        this.setLoginCookie(expire);
        this.setHeaderAuthorization();

        return res;
      }
      throw res;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  setLoginCookie(expireStr) {
    // 若没有，则一小时后过期
    const expireDate = expireStr ? new Date(expireStr) : new Date(Date.now() + 1000 * 60 * 60);
    setCookie(AuiUserIdCookieKey, this.userId);
    setCookie(AuiUserNameCookieKey, this.userName);
    setCookie(AuiUserToken, this.authToken);

    function setCookie(key, value) {
      const cookieStr = serialize(key, value, { expires: expireDate });
      document.cookie = cookieStr;
    }
  }

  // 将登录 token 设置为请求 header 的 Authorization，Appserver 要求加上 Bearer 前缀
  setHeaderAuthorization() {
    this.request.defaults.headers.common.Authorization = `Bearer ${this.authToken}`;
  }

  // 这里的 token 是 Interaction SDK 所需要使用的，不是接口所使用的登录 token
  async getToken(userId) {
    const { InteractionEngine } = window.AliyunInteraction;
    const deviceId = InteractionEngine.getDeviceId();

    const res = await this.request.post(ApiNames.token, {
      user_id: userId || this.userId,
      device_type: 'web',
      device_id: deviceId,
    });
    return res;
  }

  // 获取房间列表
  async getRoomList(pageNum, pageSize) {
    try {
      const res = await this.request.post(ApiNames.list, {
        user_id: this.userId,
        page_num: pageNum,
        page_size: pageSize,
      });
      return convertToCamel(res);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  // 获取房间详情
  async getRoomDetail(roomId) {
    try {
      const res = await this.request.post(ApiNames.get, {
        user_id: this.userId,
        id: roomId,
      });
      const detail = convertToCamel(res);
      return detail;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  // 创建房间
  async createRoom(roomName) {
    const res = await this.request.post(ApiNames.create, {
      title: roomName,
      anchor: this.userId,
      anchor_nick: this.userName,
    });
    const detail = convertToCamel(res);
    return detail;
  }
}


export default new Services();
